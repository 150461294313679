import { type GuyMeta } from "../../model/GuyMeta";
import { type SocialNetwork } from "../../model/SocialNetwork";
import { Text } from "../../ui-kit/Text/Text";
import { socialNetworks } from "./socialNetworks";

export const renderSocialNetworks = (sn: GuyMeta["socialLinks"]) =>
  Object.entries(sn)
    .filter(([, url]) => Boolean(url))
    .map(([key, url]) => {
      const network = socialNetworks[key as SocialNetwork];

      return (
        <div key={key + url} className="flex gap-2">
          <div>
            <Text color="text-title" weight="font-bold">
              {network.title}:
            </Text>
          </div>
          <div>
            <a
              className="underline text-white"
              target="_blank"
              rel="noreferrer"
              href={network.url ? `${network.url}/${url}` : url}
            >
              <Text>{url}</Text>
            </a>
          </div>
        </div>
      );
    });
