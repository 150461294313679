import { type FC } from "react";
import { GuyClass } from "../../model/Guy/GuyClass";
import { GuyType } from "../../model/Guy/GuyType";
import { Text } from "../../ui-kit/Text/Text";
import { GUYS } from "./guys";

interface PerksProps {
  guyType: GuyType;
  guyClass: GuyClass;
}

export const Perks: FC<PerksProps> = ({ guyClass, guyType }) => {
  const selectedClass = GUYS[guyClass];
  const selectedGuy = selectedClass.guys[guyType]!;

  return (
    <div>
      <p className="whitespace-pre-wrap">
        <Text>{selectedGuy?.perks.text}</Text>
      </p>
      {selectedGuy?.perks.list ? (
        <>
          <ul className="list-inside list-disc my-4 space-y-2">
            {selectedGuy?.perks.list.map((li, i) => (
              <li className={i === 2 ? "text-title" : "text-white"}>
                <Text
                  color={i === 2 ? "text-title" : undefined}
                  weight={i === 2 ? "font-bold" : undefined}
                  key={String(i)}
                >
                  {li}
                </Text>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
};
