import { type FC } from "react";
import { Link } from "react-router-dom";
import { GuyPreview } from "../../components/GuyPreview/GuyPreview";
import { type GuyMeta } from "../../model/GuyMeta";
import { Text } from "../../ui-kit/Text/Text";
import { getGuyBgStyle } from "../../utils/getGuyBgStyle";

interface GuyListProps {
  guys: GuyMeta[];
}

export const GuysList: FC<GuyListProps> = ({ guys }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {guys.map(renderGuy)}
    </div>
  );
};

const renderGuy = (meta: GuyMeta, i: number) => (
  <div key={String(i)} className="flex flex-col items-center">
    <Link
      to={`/guy/${meta.id}`}
      className="flex justify-center items-center p-2 w-36 h-36 mb-2 border border-black"
      style={getGuyBgStyle(meta.guy.background)}
    >
      <GuyPreview
        guyClass={meta.guy.guyClass}
        guyType={meta.guy.guyType}
        color={meta.guy.color}
        height={128}
      />
    </Link>
    <Text size="md">#{meta.id}</Text>
  </div>
);
