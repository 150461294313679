import axios from "axios";
import { useEffect, useState, type FC } from "react";
import { PageLayout } from "../../layouts/PageLayout/PageLayout";
import { type GuyMeta } from "../../model/GuyMeta";
import { GuyForm } from "./GuyForm";

export const BuildPage: FC = () => {
  const [guys, setGuys] = useState<GuyMeta[]>([]);

  useEffect(() => {
    axios.get("/api/guys").then((res) => setGuys(res.data.data));
  }, []);

  return (
    <PageLayout width="max-w-xl">
      {guys.length <= 100 ? <GuyForm /> : null}
    </PageLayout>
  );
};
