import { type GuyBackground } from "../../model/Guy/GuyBackground";

export const DEFAULT_BACKGROUND: GuyBackground = "seaBlue";

export const SOLID_BACKGROUND_MAP = {
  seaBlue: { label: "SOLID - SEA BLUE", color: "#497DA3" },
  pink: { label: "SOLID - PINK", color: "#F1B4B4" },
  teal: { label: "SOLID - TEAL", color: "#49A38D" },
  blue: { label: "SOLID - BLUE", color: "#494DA3" },
  purple: { label: "SOLID - PURPLE", color: "#6049A3" },
  taffy: { label: "SOLID - TAFFY", color: "#A3499F" },
  red: { label: "SOLID - RED", color: "#A3495F" },
  orange: { label: "SOLID - ORANGE", color: "#A37449" },
  gold: { label: "SOLID - GOLD", color: "#BEBA56" },
  green: { label: "SOLID - GREEN", color: "#5A9745" },
  transparent: { label: "TRANSPARENT", color: "" },
};

export const GIF_BACKGROUND_MAP = {
  trippy: { label: "GIF - TRIPPY", src: "/background/trippy.gif" },
  tesselation: {
    label: "GIF - TESSELATION",
    src: "/background/tesselation.gif",
  },
  rainbow: { label: "GIF - RAINBOW", src: "/background/rainbow.gif" },
  shimmer: { label: "GIF - SHIMMER", src: "/background/shimmer.gif" },
  guthix: { label: "GIF - GUTHIX", src: "/background/guthix.gif" },
  wonder: { label: "GIF - WONDER", src: "/background/wonder.gif" },
  thunder: { label: "GIF - THUNDER", src: "/background/thunder.gif" },
  japan: { label: "GIF - JAPAN", src: "/background/japan.gif" },
  bathhouse: { label: "GIF - BATH HOUSE", src: "/background/bathhouse.gif" },
  soviet: { label: "GIF - SOVIET", src: "/background/soviet.gif" },
  dusk: { label: "GIF - DUSK", src: "/background/dusk.gif" },
  glitch: { label: "GIF - GLITCH", src: "/background/glitch.gif" },
  pinkwonder: { label: "GIF - PINK WONDER", src: "/background/pinkwonder.gif" },
  speedstar: { label: "GIF - SPEEDSTAR", src: "/background/speedstar.gif" },
  starfall: { label: "GIF - STARFALL", src: "/background/starfall.gif" },
};

export const BACKGROUND: Record<
  GuyBackground,
  { label: string; color?: string; src?: string }
> = {
  ...SOLID_BACKGROUND_MAP,
  ...GIF_BACKGROUND_MAP,
};
