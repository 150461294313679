import axios from "axios";
import { useEffect, useMemo, useState, type FC } from "react";
import { PageLayout } from "../../layouts/PageLayout/PageLayout";
import { type GuyMeta } from "../../model/GuyMeta";
import { Text } from "../../ui-kit/Text/Text";
import { GuysList } from "./GuysList";

export const StartPage: FC = () => {
  const [guys, setGuys] = useState<GuyMeta[]>([]);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    axios.get("/api/guys").then((res) => setGuys(res.data.data));
  }, []);

  const filteredGuys = useMemo(
    () =>
      guys.filter((g) =>
        g.guy.name.toLowerCase().includes(searchStr.toLowerCase())
      ),
    [guys, searchStr]
  );

  return (
    <div className="w-full max-w-4xl flex flex-col">
      <div className="space-8">
        <div className="flex flex-col items-center">
          <div className="w-full flex items-center gap-4">
            <input
              className="px-4 py-2 rounded-full my-4 w-full"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <Text>{`(${guys.length}/100)`}</Text>
          </div>
        </div>
      </div>
      <div className="grow">
        <PageLayout className="h-full">
          <div className="pt-4 md:pt-0">
            <GuysList guys={filteredGuys} />
          </div>
        </PageLayout>
      </div>
    </div>
  );
};
