import axios from "axios";
import { useEffect, useState, type FC } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { PageLayout } from "../../layouts/PageLayout/PageLayout";
import { GuyMeta } from "../../model/GuyMeta";
import { Text } from "../../ui-kit/Text/Text";
import { Title } from "../../ui-kit/Title/Title";

export const SuccessPage: FC = () => {
  const [meta, setMeta] = useState<GuyMeta>();

  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    if (sessionId) {
      axios
        .get(`/api/success/${sessionId}`)
        .then((res) => setMeta(res.data.data));
    }
  }, [sessionId]);

  if (!meta) {
    return null;
  }

  return (
    <PageLayout width="max-w-4xl">
      <div className="flex flex-col items-center gap-2 md:gap-4 lg:gap-8">
        <Title
          size="h1"
          tag="h1"
          color="text-title"
          weight="font-bold"
          shadow="text-shadow-red"
        >
          CONFIRMATION
        </Title>

        <div>
          <Text size="h2" color="text-title" weight="font-bold">
            HERES YOUR DOOM GUY
          </Text>
        </div>

        <div className="flex flex-col items-center">
          <div>
            <Text
              size="h2"
              color="text-title"
              weight="font-bold"
              shadow="text-shadow-red"
            >
              {meta.guy.name}
            </Text>
          </div>
          <div>
            <Text
              size="h2"
              color="text-title"
              weight="font-bold"
              shadow="text-shadow-red"
            >
              #{meta.id}
            </Text>
          </div>
          <div className="flex justify-center mt-2 bg-white max-w-[300px] w-full">
            <img
              className="p-4"
              src={`${process.env.PUBLIC_URL}/success-guy.png`}
              alt="success-guy.png"
            />
          </div>
        </div>
        <div>
          <Link
            to={`/guy/${meta.id}`}
            className="bg-gray-400 block p-2 md:px-4 lg:px-8"
          >
            <Text size="h2" color="text-black">
              View your Marine
            </Text>
          </Link>
        </div>
      </div>
    </PageLayout>
  );
};
