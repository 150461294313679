import { forwardRef } from "react";
import { type WithClassName } from "../../model/WithClassName";
import { style } from "../../utils/style";
import { Text } from "../Text/Text";
import { renderError } from "./renderError";

interface TextInputProps extends WithClassName {
  label?: string;
  placeholder?: string;
  error?: string;
  rounded?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, rounded, error, className, placeholder, ...rest }, ref) => (
    <div>
      <label>
        {label ? (
          <div className={style(!rounded && "mb-2")}>
            <Text size="md">{label}</Text>
          </div>
        ) : null}
        <input
          ref={ref}
          className={style(
            rounded
              ? "px-4 py-2 rounded-full my-4"
              : "px-4 py-2 focus:outline-none rounded-sm",
            className
          )}
          type="text"
          placeholder={placeholder}
          {...rest}
        />
      </label>
      {renderError(error)}
    </div>
  )
);
