import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState, type FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { GuyPreview } from "../../components/GuyPreview/GuyPreview";
import { type GuyMeta } from "../../model/GuyMeta";
import { Button } from "../../ui-kit/Button/Button";
import { ColorPicker } from "../../ui-kit/ColorPicker/ColorPicker";
import { Selector } from "../../ui-kit/Selector/Selector";
import { Text } from "../../ui-kit/Text/Text";
import { TextInput } from "../../ui-kit/TextInput/TextInput";
import { Title } from "../../ui-kit/Title/Title";
import { getGuyBgStyle } from "../../utils/getGuyBgStyle";
import { Perks } from "./Perks";
import { SocialLinks } from "./SocialLinks";
import { BACKGROUND, DEFAULT_BACKGROUND } from "./background";
import { GUYS } from "./guys";
import { PROFILE_MESSAGE_DESCRIPTION } from "./profileMessageDescription";

const schema = yup
  .object<GuyMeta>({
    guy: yup.object({
      name: yup.string().max(64).required(),
    }),
    profileMessage: yup.string().max(256),
    firstSong: yup.object({
      name: yup.string().max(64),
      artist: yup.string().max(64),
    }),
    secondSong: yup.object({
      name: yup.string().max(64),
      artist: yup.string().max(64),
    }),
    socialLinks: yup.object({
      instagram: yup.string().max(64),
      twitter: yup.string().max(64),
      youtube: yup.string().max(64),
      tiktok: yup.string().max(64),
      personalSite: yup.string().max(128),
    }),
  })
  .required();

// TODO: Make it more readable
export const GuyForm: FC = () => {
  const {
    control,
    register,
    handleSubmit: onSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<GuyMeta>({
    defaultValues: {
      guy: {
        guyClass: "infantry",
        guyType: "rifle",
        background: DEFAULT_BACKGROUND,
        color: { r: 0, g: 255, b: 0 },
      },
    },
    resolver: yupResolver(schema) as any,
  });

  const [loading, setLoading] = useState(false);

  const formData = watch();

  useEffect(() => {
    switch (formData.guy.guyClass) {
      case "infantry":
        setValue("guy.guyType", "rifle");
        break;
      case "specialForces":
        setValue("guy.guyType", "plasma");
        break;
      case "heavyArtillery":
        setValue("guy.guyType", "10k");
        break;
    }
  }, [formData.guy.guyClass, setValue]);

  const selectedClass = GUYS[formData.guy.guyClass];

  const handleSubmit = async (data: GuyMeta) => {
    setLoading(true);
    axios
      .post("/api/pay", data)
      .then((res) => {
        setLoading(false);
        globalThis.location.href = res.data.url;
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form className="max-w-sm m-auto" onSubmit={onSubmit(handleSubmit)}>
      {/* Guy selector */}
      <div className="space-y-6 lg:space-y-8 mb-4 md:mb-8 xl:mb-12">
        {/* Name */}
        <div>
          <Text size="md">USERNAME</Text>
          <div className="flex flex-col items-center">
            <TextInput
              {...register("guy.name")}
              error={errors.guy?.name?.message}
              rounded
            />
          </div>
        </div>

        {/* Color */}
        <div>
          <label>
            <div className="mb-2">
              <Text size="md">COLOR</Text>
            </div>
            <div className="flex justify-center">
              <Controller
                name="guy.color"
                control={control}
                render={({ field }) => <ColorPicker {...field} />}
              />
            </div>
          </label>
        </div>

        {/* CLASS, SKIN */}
        <div>
          <label className="block mb-8">
            <div className="mb-2">
              <Text size="md">CLASS</Text>
            </div>
            <Controller
              name="guy.guyClass"
              defaultValue="infantry"
              control={control}
              render={({ field }) => (
                <Selector
                  className="h-9"
                  options={Object.entries(GUYS).map(([key, { label }]) => ({
                    label,
                    value: key,
                  }))}
                  {...field}
                />
              )}
            />
          </label>
          <label>
            <div className="mb-2">
              <Text size="md">SKIN</Text>
            </div>
            <Controller
              name="guy.guyType"
              defaultValue="rifle"
              control={control}
              render={({ field }) => (
                <Selector
                  className="h-9"
                  options={Object.entries(selectedClass.guys).map(
                    ([key, { label }]) => ({
                      label,
                      value: key,
                    })
                  )}
                  {...field}
                />
              )}
            />
          </label>
          <div className="flex justify-center mt-8">
            <GuyPreview {...formData.guy} buttonRotation />
          </div>
        </div>

        {/* Background */}
        <div>
          <label>
            <Controller
              name="guy.background"
              defaultValue="seaBlue"
              control={control}
              render={({ field }) => (
                <Selector
                  title="BACKGROUND"
                  options={Object.entries(BACKGROUND).map(
                    ([key, { label }]) => ({ label: label, value: key })
                  )}
                  {...field}
                />
              )}
            />
          </label>
          <div className="mt-4 flex justify-center">
            <Text color="text-title">
              {BACKGROUND[formData.guy.background].label}
            </Text>
          </div>
          <div className="flex justify-center">
            <div
              className="mt-8 w-72 h-[266px]"
              style={getGuyBgStyle(formData.guy.background)}
            />
          </div>
        </div>
      </div>

      {/* PERKS */}
      <div className="space-y-6 mb-4 md:mb-8 xl:mb-12">
        <div className="mb-6">
          <Title
            color="text-title"
            size="h3"
            shadow="text-shadow-red"
            weight="font-bold"
          >
            PERKS
          </Title>
        </div>
        <Perks
          guyClass={formData.guy.guyClass}
          guyType={formData.guy.guyType}
        />
      </div>

      {/* Profile message */}
      <div className="space-y-6 mb-4 md:mb-8 xl:mb-12">
        <div className="mb-2">
          <Title
            color="text-title"
            size="h3"
            shadow="text-shadow-red"
            weight="font-bold"
          >
            PROFILE MESSAGE
          </Title>
        </div>
        <p>
          <Text>{PROFILE_MESSAGE_DESCRIPTION}</Text>
        </p>
        <textarea
          className="h-56 w-full rounded-sm px-4 py-2 focus:outline-none"
          {...register("profileMessage")}
        />
        <p className="text-red-600">{errors?.profileMessage?.message || ""}</p>
      </div>

      {/* Social links */}
      <SocialLinks control={control} errors={errors} />

      {/* Submit */}
      <div className="flex justify-center">
        <Button className="mt-2" disabled={loading} rounded>
          {loading ? "LOADING..." : "COMPLETE"}
        </Button>
      </div>
    </form>
  );
};
