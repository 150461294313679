import { type FC } from "react";
import { Text, type TextProps } from "../Text/Text";

interface TitleProps extends TextProps {
  tag?: "h1" | "h2" | "h3";
}

export const Title: FC<TitleProps> = ({ tag = "h3", ...textProps }) => {
  const Tag = tag;

  return (
    <Tag>
      <Text {...textProps} />
    </Tag>
  );
};
