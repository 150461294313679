import { forwardRef } from "react";
import { RgbColorPicker } from "react-colorful";

import "./ColorPicker.css";

export const ColorPicker = forwardRef<HTMLInputElement, any>(
  ({ value, ...rest }, ref) => {
    return (
      <div ref={ref}>
        <RgbColorPicker color={value} {...rest} />
      </div>
    );
  }
);
