import { GuyClass } from "../../model/Guy/GuyClass";
import { GuyType } from "../../model/Guy/GuyType";

interface Guy {
  label: string;
  spriteCount: number;
  perks: {
    text: string;
    list: string[];
  };
}

interface GuyClassData {
  label: string;
  guys: Partial<Record<GuyType, Guy>>;
}

type GuysData = Record<GuyClass, GuyClassData>;

const DEFAULT_PERKS_LIST = [
  "1x Tier 2 Marine",
  "Extremely early access to our games private test group, including hidden perks.",
  "And a Doomkit Hoodie of your choice",
];

export const GUYS: GuysData = {
  infantry: {
    label: "Infantry",
    guys: {
      rifle: {
        label: "Rifle",
        spriteCount: 8,
        perks: {
          text: "Your standard Marine. Dependable, trustworthy, kill count in the hundreds of thousands. The tier-one marine class comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      shotgun: {
        label: "Super Shotgun",
        spriteCount: 5,
        perks: {
          text: "What's brown, has two barrels, and turns things into confetti? Super Shot Gun Marine of course! He can’t do math or taxes, but there’s few problems this bad boy can’t solve at close range. This tier-one marine class comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      machinegun: {
        label: "Machine Gun",
        spriteCount: 7,
        perks: {
          text: "“No matter how many people you kill, using a machine gun isn't a war crime. It simply performs its job horrifyingly well.” — Sebastian Junger.\n\nThis is who you call when you need to send 1 or two more bullets per second the other guy's way to secure victory. This tier-one marine class comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
    },
  },
  specialForces: {
    label: "Special Forces",
    guys: {
      plasma: {
        label: "Plasma",
        spriteCount: 5,
        perks: {
          text: "Considered a jack of all trades, this special force marine is well versed in the way of the Plasma Rifle. With its devastating barrage of blue-white Plasma balls, god himself would think twice about standing in front of this thing. The tier two marine class comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      flame: {
        label: "Flame Cannon",
        spriteCount: 5,
        perks: {
          text: "“What’s that smell? The Ashes of my Enemies.”. — Sgt.Mcguiness.\n\nThis fiery tier-two Marine poses many risks to it’s operator. The first disadvantage is the weapon’s weight and length, which impairs the soldier’s mobility. The second risk is, by the time you get to the guy he’s already turned into BBQ. This fiery tier-two marine comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      revenant: {
        label: "Revenant",
        spriteCount: 5,
        perks: {
          text: "“In the darkness of night, I stared into the abyss, and the abyss stared back. And like an Angel of glowing light, two amber lights flew with brilliance towards me. A sign from the heavens amid darkness? — No — Heat seeking death prepared to follow me to the ends of the earth” —- Gullible-Guys Travels.\n\nThis specialist class Marine contains two shoulder-mounted rockets that fire two heat-seeking missiles. They’re just as sinister as they sound. This explosive tier two class marine comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
    },
  },
  heavyArtillery: {
    label: "Heavy Artillery",
    guys: {
      "10k": {
        label: "10K",
        spriteCount: 5,
        perks: {
          text: "Trained to use an advanced model of the BFG 9000, The BFG 10K resembling a cannon, takes a few seconds to charge. Upon firing, it makes mothers' sons disappear. This explosive tier three class marine comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      railgun: {
        label: "Railgun",
        spriteCount: 5,
        perks: {
          text: "Typically, Railguns are still at the research stage after decades of R&D, and it remains to be seen whether they will be deployed as practical weapons in the foreseeable future. This rail gun, however, is a different story. This compact yet devastating railgun is just light enough for a reasonably strong man to carry, yet powerful enough to hit the enemy team; with what feels like a flying school bus. This tier three class marine comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
      rocket: {
        label: "Rocket Launcher",
        spriteCount: 5,
        perks: {
          text: "The ruggedness, simplicity, low cost, and effectiveness of the rocket launcher have made it the most widely used anti-armor weapon in the world. So justifiably, this marine has decades of experience, making big things stop -- Permanently. This explosive tier three class marine comes with the following perks:",
          list: DEFAULT_PERKS_LIST,
        },
      },
    },
  },
};
