import { type CSSProperties } from "react";
import { type GuyBackground } from "../model/Guy/GuyBackground";
import { BACKGROUND } from "../pages/Build/background";

export const getGuyBgStyle = (background: GuyBackground): CSSProperties => {
  const bg = BACKGROUND[background];
  return {
    backgroundColor: bg.color,
    backgroundImage: bg.src
      ? `url(${process.env.PUBLIC_URL}${bg.src})`
      : undefined,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
};
