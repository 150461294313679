import { type FC } from "react";
import { type WithChildren } from "../../model/WithChildren";
import { style } from "../../utils/style";

type TextColor = `text-${"white" | "title" | "topic" | "black" | "gray"}`;
type TextShadow = `text-shadow-${"gray" | "red"}`;
type TextSize = "h1" | "h2" | "h3" | "sm" | "md" | "xl" | "";
type TextWeight = `font-${"normal" | "bold"}`;
type Decoration = "underline";

const textSizeStyleMap: Record<TextSize, string> = {
  h1: "text-h3 md:text-h2 lg:text-h1",
  h2: "text-xl md:text-h3 lg:text-h2",
  h3: "text-md md:text-xl lg:text-h3",
  xl: "text-sm md:text-md lg:text-xl",
  md: "text-sm md:text-sm lg:text-sm",
  sm: "text-sm",
  "": "",
};

export interface TextProps extends WithChildren {
  color?: TextColor;
  shadow?: TextShadow;
  size?: TextSize;
  weight?: TextWeight;
  decoration?: Decoration;
}

export const Text: FC<TextProps> = ({
  color = "text-white",
  shadow,
  size = "",
  weight = "font-normal",
  decoration,
  children,
}) => (
  <span
    className={style(color, shadow, textSizeStyleMap[size], weight, decoration)}
  >
    {children}
  </span>
);
