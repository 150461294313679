import { type RGB } from "../../model/Rgb";
import { hsvToRgb } from "./hsvToRbg";
import { rgbToHsv } from "./rgbToHsv";

export const repaint = (data: Uint8ClampedArray, rgb: RGB, i: number) => {
  const dataR = data[i];
  const dataG = data[i + 1];
  const dataB = data[i + 2];

  const colorPickerPixelHsv = rgbToHsv(rgb);
  const imagePixelHsv = rgbToHsv({ r: dataR, g: dataG, b: dataB });

  imagePixelHsv.h = colorPickerPixelHsv.h;

  const imagePixelRgb = hsvToRgb(imagePixelHsv);

  data[i] = imagePixelRgb.r;
  data[i + 1] = imagePixelRgb.g;
  data[i + 2] = imagePixelRgb.b;
};
