import axios from "axios";
import { useEffect, useState, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GuyPreview } from "../../components/GuyPreview/GuyPreview";
import { PageLayout } from "../../layouts/PageLayout/PageLayout";
import { type GuyMeta } from "../../model/GuyMeta";
import { Text } from "../../ui-kit/Text/Text";
import { Title } from "../../ui-kit/Title/Title";
import { renderSocialNetworks } from "./renderSocialNetworks";

const NO_MESSAGE = "No info";

export const GuyPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [guyMeta, setGuyMeta] = useState<GuyMeta>();

  useEffect(() => {
    if (id && !guyMeta) {
      axios
        .get(`/api/guy/${id}`)
        .then((res) => setGuyMeta(res.data.data))
        .catch(() => navigate("/"));
    }
  }, [guyMeta, id, navigate]);

  return (
    <PageLayout>
      {guyMeta ? (
        <div className="space-y-4 md:space-y-8 lg:space-y-12">
          <div className="flex flex-col items-start gap-4 md:flex-row-reverse md:items-start md:justify-end">
            <div className="flex flex-col items-center space-y-2 mb-2">
              <Title
                size="h1"
                tag="h1"
                color="text-title"
                shadow="text-shadow-red"
                weight="font-bold"
              >
                {guyMeta.guy.name}
              </Title>
              <Text
                size="h1"
                color="text-title"
                shadow="text-shadow-red"
                weight="font-bold"
              >
                #{guyMeta.id}
              </Text>
            </div>
            <div className="border-4 border-yellow-400">
              <GuyPreview
                guyClass={guyMeta.guy.guyClass}
                guyType={guyMeta.guy.guyType}
                color={guyMeta.guy.color}
                background={guyMeta.guy.background}
                autoRotation
              />
            </div>
          </div>
          <div className="p-2 rounded-md bg-white max-w-sm">
            <p className="min-h-[100px]">
              <Text color="text-black">
                {guyMeta.profileMessage || NO_MESSAGE}
              </Text>
            </p>
          </div>
          {guyMeta.socialLinks ? (
            <div className="space-y-2">
              {renderSocialNetworks(guyMeta.socialLinks)}
            </div>
          ) : null}
        </div>
      ) : null}
    </PageLayout>
  );
};
