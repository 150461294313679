import { type FC } from "react";
import { Link } from "react-router-dom";
import { type WithClassName } from "../../model/WithClassName";
import { Title } from "../../ui-kit/Title/Title";
import { style } from "../../utils/style";

const TITLE = "BUILD - A - GUY";

interface HeaderProps extends WithClassName {}

export const Header: FC<HeaderProps> = ({ className }) => (
  <header
    className={style(
      "flex flex-col items-center justify-center gap-y-7",
      className
    )}
  >
    <Link to="/">
      <Title
        tag="h1"
        color="text-title"
        size="h1"
        shadow="text-shadow-red"
        weight="font-bold"
      >
        {TITLE}
      </Title>
    </Link>
  </header>
);
