import { useEffect, type FC } from "react";
import { type WithChildren } from "../../model/WithChildren";
import { type WithClassName } from "../../model/WithClassName";
import { style } from "../../utils/style";

interface PageLayoutProps extends WithChildren, WithClassName {
  width?: "max-w-4xl" | "max-w-xl";
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  width = "max-w-4xl",
  className,
}) => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div
      className={style(
        "w-full bg-background-light rounded-md p-2 md:p-6 lg:px-12 lg:py-6",
        width,
        className
      )}
    >
      {children}
    </div>
  );
};
