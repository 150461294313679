import { type FC } from "react";
import { Controller, type Control, type FieldErrors } from "react-hook-form";
import { type GuyMeta } from "../../model/GuyMeta";
import { Text } from "../../ui-kit/Text/Text";
import { TextInput } from "../../ui-kit/TextInput/TextInput";
import { Title } from "../../ui-kit/Title/Title";

interface SocialLinksProps {
  control: Control<GuyMeta, any>;
  errors: FieldErrors<GuyMeta>;
}

export const SocialLinks: FC<SocialLinksProps> = ({ control, errors }) => (
  <div className="space-y-6 mb-4 md:mb-8 xl:mb-12">
    <div>
      <Title
        color="text-title"
        size="h3"
        shadow="text-shadow-red"
        weight="font-bold"
      >
        Social Links
      </Title>
    </div>
    <p>
      <Text>
        An optional link for self-promotion. Visible from your Marine’s profile.
      </Text>
    </p>
    <Controller
      name="socialLinks.instagram"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <TextInput
          className="w-full"
          label="Instagram"
          placeholder="myawesomenickname"
          error={errors.socialLinks?.instagram?.message}
          {...field}
        />
      )}
    />
    <Controller
      name="socialLinks.personalSite"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <TextInput
          className="w-full"
          label="Personal Website"
          placeholder="myawesomewebsite.com"
          error={errors.socialLinks?.personalSite?.message}
          {...field}
        />
      )}
    />
  </div>
);
