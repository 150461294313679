import React from "react";
import ReactDOM from "react-dom/client";

import {
  Navigate,
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./index.css";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { BuildPage } from "./pages/Build/Build";
import { GuyPage } from "./pages/Guy/Guy";
import { StartPage } from "./pages/Start/Start";
import { SuccessPage } from "./pages/Success/Success";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route path="/" element={<Navigate to="/build" />} />
      <Route path="/guys" element={<StartPage />} />
      <Route path="/build" element={<BuildPage />} />
      <Route path="/guy/:id" element={<GuyPage />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="*" element={<Navigate to="/build" />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
