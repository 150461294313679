import { forwardRef } from "react";
import { ArrowLeft } from "../../icons/ArrowLeft";
import { type WithClassName } from "../../model/WithClassName";
import { style } from "../../utils/style";
import { Text } from "../Text/Text";

interface SelectorProps extends WithClassName {
  options?: { label: string; value: string }[];
  value?: string;
  title?: string;
  onChange: any;
}

export const Selector = forwardRef<HTMLInputElement, SelectorProps>(
  ({ className, title, options = [], value, onChange }, ref) => {
    const activeIndex = options?.findIndex((option) => option.value === value);
    const currentOption = options[activeIndex];

    const handleNextClick = () => {
      if (activeIndex + 1 <= options.length - 1) {
        onChange(options[activeIndex + 1].value || value);
      } else {
        onChange(options[0].value || value);
      }
    };

    const handlePrevClick = () => {
      if (activeIndex - 1 >= 0) {
        onChange(options[activeIndex - 1].value || value);
      } else {
        onChange(options[options.length - 1].value || value);
      }
    };

    return (
      <div
        className={style("flex items-center justify-between", className)}
        ref={ref}
      >
        <button type="button" onClick={handlePrevClick}>
          <ArrowLeft />
        </button>
        <Text
          color="text-title"
          shadow="text-shadow-red"
          size="xl"
          weight="font-bold"
        >
          {title || currentOption?.label}
        </Text>
        <button type="button" onClick={handleNextClick}>
          <ArrowLeft className="rotate-180" />
        </button>
      </div>
    );
  }
);
