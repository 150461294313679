import { useCallback, useEffect, useRef, useState, type FC } from "react";
import { type GuyBackground } from "../../model/Guy/GuyBackground";
import { type GuyMeta } from "../../model/GuyMeta";
import { GUYS } from "../../pages/Build/guys";
import { Button } from "../../ui-kit/Button/Button";
import { getGuyBgStyle } from "../../utils/getGuyBgStyle";
import { pixelNeedRepaint } from "./pixelNeedRepaint";
import { repaint } from "./repaint";

const FRAMES_BASE_PATH = `${process.env.PUBLIC_URL}/guys`;
const FIRST_FRAME: number = 1;
const CHANGE_FRAME_INTERVAL: number = 750;

const getNextFrame =
  (framesCount: number) =>
  (currentFrame: number): number =>
    currentFrame + 1 > framesCount ? 1 : currentFrame + 1;

interface GuyPreviewProps {
  guyClass: GuyMeta["guy"]["guyClass"];
  guyType: GuyMeta["guy"]["guyType"];
  color?: GuyMeta["guy"]["color"];
  background?: GuyBackground;
  autoRotation?: boolean;
  buttonRotation?: boolean;
  height?: 128 | 250;
}

export const GuyPreview: FC<GuyPreviewProps> = ({
  guyClass,
  guyType,
  background,
  autoRotation,
  buttonRotation,
  color = {
    r: 0,
    g: 0,
    b: 0,
  },
  height = 250,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [frameIndex, setFrameIndex] = useState(FIRST_FRAME);

  const framesCount = GUYS[guyClass].guys[guyType]?.spriteCount || 1;

  const handleRotate = useCallback(
    () => setFrameIndex(getNextFrame(framesCount)),
    [framesCount]
  );

  const framesDir = `${guyClass}/${guyType}`;
  const frameUrl = `${FRAMES_BASE_PATH}/${framesDir}/${frameIndex}.png`;

  useEffect(() => {
    if (autoRotation) {
      const interval = setInterval(
        () => setFrameIndex(getNextFrame(framesCount)),
        CHANGE_FRAME_INTERVAL
      );

      return () => clearInterval(interval);
    }
  }, [autoRotation, framesCount]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    const image = new Image();

    image.src = frameUrl;
    image.onload = () => {
      const targetHeight = height;
      const aspectRatio = image.width / image.height;
      const targetWidth = targetHeight * aspectRatio;

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < imageData.data.length; i += 4) {
        const pixelRed = imageData.data[i];
        const pixelGreen = imageData.data[i + 1];
        const pixelBlue = imageData.data[i + 2];

        if (pixelNeedRepaint(pixelRed, pixelGreen, pixelBlue)) {
          repaint(imageData.data, color, i);
        }
      }

      ctx.putImageData(imageData, 0, 0);
    };
  }, [color, frameUrl, height]);

  return (
    <div className="space-y-4 w-72">
      <div
        className="flex justify-center p-2"
        style={background ? getGuyBgStyle(background) : undefined}
      >
        <canvas ref={canvasRef} />
      </div>
      {!autoRotation && buttonRotation ? (
        <div className="flex justify-center">
          <Button onClick={handleRotate} type="button" rounded>
            ROTATE
          </Button>
        </div>
      ) : null}
    </div>
  );
};
