import { type SocialNetwork } from "../../model/SocialNetwork";

interface SocialNetworkInfo {
  title: string;
  url: string;
}

export const socialNetworks: Record<SocialNetwork, SocialNetworkInfo> = {
  instagram: {
    title: "Instagram",
    url: "https://instagram.com",
  },
  youtube: {
    title: "YouTube",
    url: "https://youtube.com",
  },
  twitter: {
    title: "Twitter",
    url: "https://twitter.com",
  },
  tiktok: {
    title: "TikTok",
    url: "https://tiktok.com",
  },
  personalSite: {
    title: "Personal website",
    url: "",
  },
};
