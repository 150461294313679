import { type FC } from "react";
import { Link, Outlet } from "react-router-dom";
import { Text } from "../../ui-kit/Text/Text";
import { Header } from "./Header";

export const MainLayout: FC = () => (
  <div className="flex flex-col justify-between min-h-screen bg-background-dark px-4 pt-4 md:px-8 md:pt-8 lg:px-12 lg:pt-12">
    <div className="grow flex flex-col">
      <Header className="mb-6" />
      <div className="grow flex justify-center">
        <Outlet />
      </div>
    </div>
    <footer className="flex justify-center py-4 md:py-8 lg:py-10">
      <Link to="/">
        <Text
          size="h3"
          color="text-title"
          shadow="text-shadow-red"
          weight="font-bold"
        >
          BUILD - A - GUY
        </Text>
      </Link>
    </footer>
  </div>
);
