import { SVGProps, type FC } from "react";

export const ArrowLeft: FC<SVGProps<any>> = (props) => (
  <svg
    width="42"
    height="27"
    viewBox="0 0 42 27"
    fill="#DCA928"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.0310237 13.1741L41.3629 0.0461082L41.4166 26.1319L0.0310237 13.1741Z"
      fill="#DCA928"
    />
  </svg>
);
