import { type FC } from "react";
import { type WithChildren } from "../../model/WithChildren";
import { type WithClassName } from "../../model/WithClassName";
import { style } from "../../utils/style";
import { Text } from "../Text/Text";

interface ButtonProps extends WithChildren, WithClassName {
  text?: string;
  type?: "submit" | "button";
  rounded?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  className,
  text,
  children,
  type = "submit",
  rounded,
  disabled,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={style(
        "px-8 py-2",
        "bg-background-gray",
        "hover:bg-gray-400",
        "active:bg-gray-500",
        rounded && "rounded-3xl",
        className
      )}
      type={type}
    >
      <Text color="text-black" weight="font-bold" size="md">
        {text || children}
      </Text>
    </button>
  );
};
